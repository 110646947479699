import { Box, CircularProgress } from "@mui/material";
import { getHeaders } from "auth/authService";
import { bool } from "aws-sdk/clients/signer";
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

type embedUrlResponseType = {

EmbedUrl: string,
Status: number,

}

export const getUrl = async (dashboardId: string): Promise<string> => {

  const headers = await getHeaders();

  try {
    const fetchedPost: any = await fetch(`${process.env.REACT_APP_API_URL}/analytics/getEmbedUrl/${dashboardId}`, {
      mode: 'cors',
      method: 'get',
      headers: headers,
    });
    
    const data: embedUrlResponseType = await fetchedPost.json();
    console.log("embed url ", data);
   
    return data.EmbedUrl;
  } catch (error) {
    console.error("Error:", error);
    return "Error getting analytics url";
  }
};


const ShowAnalytics = (): JSX.Element => {
  const [analyticsUrl, setAnalyticsUrl] = useState<string>('');
  const { dashboardId } = useParams();
  console.log("🚀 ~ ShowAnalytics ~ dashboardId:", dashboardId);
  const [loading, setLoading] = useState<boolean>(true);
  console.log("🚀 ~ ShowAnalytics ~ loading:", loading);
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const result = await getUrl(dashboardId);
      if (result) {
        console.log("result: ", result);
        setAnalyticsUrl(result.toString());
      }
      setLoading(false);
    };
    fetchData();
}, [dashboardId]);
  return (

    <>
    {loading ? 
      <MDBox sx={{ display: 'flex', alignItems: 'center',   justifyContent: "center"}}>
        <CircularProgress color="inherit" sx={{ alignItems: "center", marginTop: "20rem" }}/>
      </MDBox>
    :
      <MDBox style={{width: "100%", height: "100vh"}}>
        <iframe title={analyticsUrl} src={analyticsUrl} width="100%" height="100%"></iframe>
      </MDBox>
    }
    </>
  );
};

export default ShowAnalytics;
