import React, { createContext, useContext, useState } from 'react';

export interface UserData {
  user: {
    user_id: string;
    given_name: string;
    family_name: string;
    company_id: string;
    jwt: string;
  }
}

export interface AppContextProps {
  userData: UserData;
  updateUserData: (newUserData: UserData) => void;
}

export const AppContext = createContext<AppContextProps | null>(null);

export const AppContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [userData, setUserData] = useState<UserData>({
    user: {
      user_id: '',
      given_name: '',
      family_name: '',
      company_id: '',
      jwt: '',
    }
  });

  const updateUserData = (newUserData: UserData) => {
    setUserData(newUserData);
  };

  const contextValue: AppContextProps = {
    userData,
    updateUserData,
  };

  return (
    <AppContext.Provider value={contextValue}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const appContext = useContext(AppContext);

  if (!appContext) {
    throw new Error('useAppContext must be used within an AppProvider');
  }

  return appContext;
};

// MyObjectContext.tsx
// import { createContext } from 'react';

// export interface UserData {
//     user: {
//         user_id: string
//         given_name: string
//         family_name: string
//         company_id: string
//         jwt: string
//     };
//     auth: typeof Auth
//   }

//   const AppContext = createContext<UserData | null>(null);

// export default AppContext;

// import { createContext, useState } from 'react';

// export interface UserData {
//   user: {
//     user_id: string;
//     given_name: string;
//     family_name: string;
//     company_id: string;
//     jwt: string;
//   };
//   auth: typeof Auth;
// }

// const initialUserData: UserData = {
//   user: {
//     user_id: '',
//     given_name: '',
//     family_name: '',
//     company_id: '',
//     jwt: '',
//   },
//   auth: Auth,
// };
// //const AppContext = createContext<UserData | null>(null);

// export const AppContext = createContext(initialUserData);

// export const AppContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
//   const [userData, setUserData] = useState<UserData | null>(null);

//   const updateUserData = (newUserData: UserData): void => {
//     setUserData(newUserData);
//   };

//   const contextValues = {
//     user: userData?.user,
//     auth: userData?.auth,
//     updateUserData,
//   };

//   return (
//     <AppContext.Provider value={contextValues}>{children}</AppContext.Provider>
//   );
// };

// export default AppContextProvider