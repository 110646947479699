/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useContext, useEffect, useState } from "react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// import Stepper from "@mui/material/Stepper";
// import Step from "@mui/material/Step";
// import StepLabel from "@mui/material/StepLabel";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";

// NewUser page components
import Property from "layouts/dashboards/properties/new-property/components/Property";

// NewUser layout schemas for form and form feilds
import validations from "layouts/dashboards/properties/new-property/schemas/validations";
import form from "layouts/dashboards/properties/new-property/schemas/form";
import initialValues from "layouts/dashboards/properties/new-property/schemas/initialValues";
import MDTypography from "components/MDTypography";
import FormField from "./components/FormField";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { getHeaders } from "auth/authService";
import {AppContext, UserData} from 'lib/appContext';

function NewProperty(): JSX.Element {

  const [searchParams] = useSearchParams();
  const companyID = searchParams.get("companyId")
  const [buttonText, setButtonText] = useState("Save")

  const { formId, formField } = form;
  const currentValidation = validations[0];
  const navigate = useNavigate();

  const sleep = (ms: any) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  const submitForm = async (values: any, actions: any) => {

    setButtonText("Saving...")

    console.log("saving new property:", values)

    //Save property
    const body = JSON.stringify(
      {
        "companyId": companyID,
        "description": values.propertyDescription,
        "name": values.propertyName,
        "enabled": false,
        "myob": false,
        "rms": false,
        "abn": values.abn,
        "rooms": values.rooms

      })
      
    const headers = await getHeaders();
    const response = await fetch(`${process.env.REACT_APP_API_URL}/property`, { 
      mode: 'cors',
      method: 'put',
      body: body,
      headers: headers,
    })

    console.log("response:", response)
    await sleep(2000);

    setButtonText("Saved")
 
    //redirect to properties page
    //navigate("/properties");

  };

  const handleSubmit = (values: any, actions: any) => {

      submitForm(values, actions);

  };

  return (
    <DashboardLayout>
      <MDBox py={3} mb={20} >
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id={formId} autoComplete="off" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <Card sx={{ height: "100%" }}>
                    <MDBox p={3}>
                      <MDBox>
                      <Property formData={{
                          values,
                          touched,
                          formField,
                          errors,
                        }} />
                        <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
                          <MDButton
                            disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            color="dark"
                          >
                            {buttonText}
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default NewProperty;
