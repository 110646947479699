/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts

// Material Dashboard 2 PRO React TS components

const full_name = `${sessionStorage.getItem("given_name")} ${sessionStorage.getItem("family_name")}`

/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Kit 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// @mui material components
import Icon from "@mui/material/Icon";

const allDashboards = JSON.parse(sessionStorage.getItem("dashboards"));
console.log("🚀 ~ allDashboards:", allDashboards);
console.log("🚀 ~ allDashboards type:", typeof allDashboards);
const dashboardRoutes = allDashboards && allDashboards.reduce((acc: any, dashboard: any) => {
  acc = [...acc, {
    label: dashboard.name,
    route: `/analytics/${dashboard.id}`,
  }];
  return acc;

}, []);
console.log("🚀 ~ dashboardRoutes ~ dashboardRoutes:", dashboardRoutes);
const routes = [
  {
    label: 'Properties',
    route: '',
    icon: <Icon fontSize="medium">apartment</Icon>,
    subMenu: [
      {
      label: "All Properties",
      route: "/properties"
    },
    {
      label: "New Property",
      route: "/properties/new-property"
    },
  
  ]
  },
  {
    label: 'Reports',  
    icon: <Icon fontSize="medium">article</Icon>,
    route:'',
    subMenu: [
      // { label: 'Portfolio Report', route: '/portfolio' },
      { label: 'All Reports', route: '/reports' },
      { label: 'Report Templates', route: 'reports/templates' },
    ],
  },

  {
    label: 'Dashboards',  
    icon: <Icon fontSize="medium">dashboard</Icon>,
    route:'',
    subMenu: dashboardRoutes,
  },
  {
    label: "You",
    icon: <Icon fontSize="medium">person</Icon>,
    route: '',
    subMenu: [
      /*
      {
        name: "My Profile",
        key: "my-profile",
        route: "/pages/profile/profile-overview",
        component: <ProfileOverview />,
      },*/
      {
        label: "Logout",
        route: "/authentication/sign-out",
        // component: <SignOut />,
      },
    ],
  },
];

// const routes = [
  
//   {
    
//     name: "properties",
//     icon: <Icon fontSize="medium">apartment</Icon>,
//     collapse: [
//       {
//         name: "Properties",
//         route: "/properties",
//         component: <PropertyTable />,
//       },
//       {
//         name: "New Property",
//         route: "/properties/new-property",
//         component: <NewProperty />,
//       },
//     ],
//   },
//   {
//     name: "Reports",
//     icon: <Icon fontSize="medium">article</Icon>,
//     collapse: [
//       {
//         name: "Reports",
//         route: "/reports",
//         component: <ReportsTable />,
//       },
//       {
//         name: "ReportTemplates",
//         route: "/reports/templates",
//         component: <ReportTemplatesTable />,
//       },
//     ]
//   },
//   {
//     title: "User Settings",
//     name: full_name,
//     icon: <Icon fontSize="medium">person</Icon>,
//     collapse: [
//       /*
//       {
//         name: "My Profile",
//         key: "my-profile",
//         route: "/pages/profile/profile-overview",
//         component: <ProfileOverview />,
//       },*/
//       {
//         name: "User Settings",
//         route: "/pages/users/settings",
//         component: <UserSettings />,
//       },
//       {
//         name: "Reset Password",
//         key: "reset-password",
//         collapse: [
//           {
//             name: "Cover",
//             route: "/authentication/reset-password/cover",
//             component: <ResetCover />,
//           },
//         ],
//       },
//       {
//         name: "Logout",
//         key: "logout",
//         route: "/authentication/sign-out",
//         component: <SignOut />,
//       },
//     ],
//   },
//   {
//     title: "Company Settings",
//     name: "Company Settings",
//     icon: <Icon fontSize="medium">content_paste</Icon>,
//     collapse: [
//       {
//         name: "Users",
//         collapse: [
//           {
//             name: "New User",
//             key: "new-user",
//             route: "/pages/users/new-user",
//             component: <NewUser />,
//           },
//         ],
//       },
//       {
//         name: "Data Tables",
//         route: "/pages/account/data-tables",
//         component: <MYOBDataTables />,
//       },
//     ],
//   }
// ];

export default routes;
