/**
PEAK REVEUNE
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import { useEffect, useRef, useState } from "react";
import MDButton from "components/MDButton";
import EditIcon  from '@mui/icons-material/Edit';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import RefreshIcon from '@mui/icons-material/Refresh';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Switch } from '@mui/material';
import { bool } from "aws-sdk/clients/signer";
import MyobImage from 'assets/images/myob.com.svg';
import RMSImage from 'assets/images/rms-logo.svg';
import { getHeaders } from "auth/authService";
import { Link } from "react-router-dom";
//function PropertyTable(): JSX.Element {
const PropertyTable: React.FC = () => {  

const [tableloaded, settableLoaded] = useState<boolean>(false)
const [dataTableData, setDataTableData] = useState<any>([])
const [companyID, setCompanyID] = useState<any>(null)
const [reportTemplates, setReportTemplates] = useState<any>([])
//const [selectedTemplates, setSelectedTemplates] = useState<Array<{ key: string; value: string }>>([]);
const selectedTemplates = useRef<Array<{ key: string; value: string }>>([]);
  
function getFinanceIcon(isConfigured:bool):JSX.Element{
  if(isConfigured){
  return(
    <img src={MyobImage} alt="myob" />
  )}
}
function getPMSIcon(isConfigured:bool):JSX.Element{
  if(isConfigured){
  return(
    <img src={RMSImage} alt="myob" width={50}/>
  )}
}

 
const loadReportTemplates = async (cid:string) => {
  try {
    const headers = await getHeaders()
    const response = await fetch(`${process.env.REACT_APP_API_URL}/reports/templates/${cid}`, {
      mode: 'cors',
      method: 'GET', 
      headers: headers,
    })
    const data = await response.json();
    const uid = sessionStorage.getItem("user_id")
    console.log("uid:", uid)
    if(!data.errors){
      //Process Return Data for all reports
      setReportTemplates(data)
    }
  } catch (error) {
    console.error("Error:", error)
  }
}

  const loadCompanyID = async () => {
    console.log("company_id:",sessionStorage.getItem("company_id") )
    const cid = sessionStorage.getItem("company_id")
    setCompanyID(cid)
    console.log("CompanyID after set: ", companyID)
  }

  useEffect(() => {
    loadCompanyID()
  },[])

  useEffect(() => {
    if(companyID!==null){
      loadReportTemplates(companyID)
    }
  },[companyID])


  function handleTemplateChange(event: SelectChangeEvent<string>) {
    selectedTemplates.current = selectedTemplates.current.filter(item => item.key !== event.target.name);
    selectedTemplates.current.push({key: event.target.name, value: event.target.value});
  }

  useEffect(() => {
    console.log("selectedTemplates:", selectedTemplates);
  }, [selectedTemplates]);

  function getTemplates(item:any, key:number): JSX.Element{
    //return a select box that lists all the report templates
    return (
      <div>
        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel id="select-template-label">Select a Template</InputLabel>
          <Select 
            name={item.id}
            id={item.id}
            onChange={handleTemplateChange}
            //value={selectedTemplates.find((template) => template.key === item.id)?.value}
            value={selectedTemplates.current?.find((template) => template.key === item.id)?.value}
            defaultValue=""
            label="Report Template"
            sx={{
              width: 200,
              height: 44,
            }}>
            {reportTemplates.map((template:{selectedComponents:[], description:string, companyID:string, UID:string, userId:string}) => (
              <MenuItem key={template.UID} value={template.UID}>
                {template.description}
              </MenuItem>
            ))}
          </Select>
          
        </FormControl>
        {/* Assuming that `template.companyId` is the correct variable */}
        &nbsp;
        <MDButton      
            sx={{marginTop: 0.5}}         
            color="info" 
            size="medium"
            iconOnly={true}
            onClick={() => reportNav(item.id)}>
            <ArrowForwardIcon/></MDButton>
            &nbsp;
        <MDButton      
            sx={{marginTop: 0.5}}         
            color="error" 
            size="medium"
            iconOnly={true}
            onClick={() => initHist(item.id)}>
            <RefreshIcon/></MDButton>
      </div>
    )
  }

  function reportNav(propertyId: string): void{
    //find the selected template based on the property ID and navigate to the view templateReport page
    const selectedTemplate = selectedTemplates.current?.find((template) => template.key === propertyId);
    if(selectedTemplate){
      window.location.href = `/reports/v/t/${companyID}/${propertyId}/${selectedTemplate.value}`;  
    }
  }

  async function initHist(propertyId: string): Promise<void>{
    console.log("setting weekly data: ", propertyId)
    try {
      const headers = await getHeaders()
      const response = await fetch(`${process.env.REACT_APP_API_URL}/components/weekly/${companyID}/${propertyId}`, {
        mode: 'cors',
        method: 'get', 
        headers: headers,
      })
      const data = await response.json();
      if(!data.errors){
        alert("Success")
      }
    } catch (error) {
      console.error("Error:", error)
    }
  }

  useEffect(() => {

    //Retrieve all properties from API
    const fetchProperties = async () => {
      
      const headers = await getHeaders();
      //console.log("Headers: ", headers)
      const response = await fetch(`${process.env.REACT_APP_API_URL}/properties/${sessionStorage.getItem("company_id")}`, {
        mode: 'cors',
        method: 'GET', 
        headers: headers,
      })
      .then((response) => response.json())
      .then((data) => {

        //console.log("RESPONSE:" ,data)
        
        const td = data.map((item: any,key: number)=> (
          {
            PropertyName: item.name,//getLink(item,item.name),
            Report: getTemplates(item,key),
            IsActive: <Switch disabled={true} checked={item.enabled} inputProps={{ 'aria-label': 'Switch A' }} />,
            Myob: <MDButton               
            color="white" 
            size="small"
            iconOnly={true}>{getFinanceIcon(item.myob)}</MDButton>,
            Pms: <MDButton               
              color="white" 
              size="small"
              iconOnly={true}>{getPMSIcon(item.rms)}</MDButton>,
            Edit: <MDButton               
              color="info" 
              size="small"
              iconOnly={true}
              href={`/properties/edit-property?propertyId=${item.id}&companyId=${item.companyId}`}>
              <EditIcon/></MDButton>,
          }
        ))

        const table = {
          columns: [
            { Header: "Property", accessor: "PropertyName" },
            { Header: "Report", accessor: "Report" },          
            { Header: "Active", accessor: "IsActive" },
            { Header: "Finance", accessor: "Myob", width:10 },
            { Header: "PMS", accessor: "Pms", width:10 },
            { Header: "", accessor: "Edit", width:10 },
          ],
          rows: td
        }
        setDataTableData(table)
        settableLoaded(true)
      })
      .catch((error) => {
        console.error("Error:", error)
      })
    }
    if(companyID!==null){
      fetchProperties()
    }

  },[reportTemplates])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>

        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              Properties 
            </MDTypography>
            <MDTypography variant="button" color="text">
              All Properties for your Company
            </MDTypography>
          </MDBox>
          {tableloaded &&
            <DataTable table={dataTableData} isSorted={false} />
          }
          <MDBox p={2}>
            <MDButton
              color="info" 
              size="medium"
              href={`/properties/new-property?companyId=${sessionStorage.getItem("company_id")}`}
            >
              Add a new Property
            </MDButton>


          </MDBox>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default PropertyTable;

