import React, { ReactElement, ReactNode, useState } from 'react';
import { AppBar, Toolbar, Typography, Tabs, Tab, Menu, MenuItem, tabsClasses, tabClasses, Avatar, IconButton } from '@mui/material';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import MDTypography from 'components/MDTypography';
import MDBox from 'components/MDBox';
import colors from "assets/theme/base/colors";
const { dark } = colors;


interface TabItem {
  label: string;
  route: string;
  icon: ReactElement;
  subMenu?: { label: string; route: string }[];

}

interface Props {
  brandName: string;
  tabs: TabItem[];
  color?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark";
  brand?: string;
}

const HorizontalAppBarWithTabsDropdown: React.FC<Props> = ({ brandName, tabs, color, brand }: Props) => {

  const history = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openTabMenuIndex, setOpenTabMenuIndex] = useState<number>(-1);
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setOpenTabMenuIndex(index);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setOpenTabMenuIndex(-1);
  };

  const handleMenuItemClick = (route: string) => {
    history(route);
    handleMenuClose();
  };

  return (
    <AppBar position="static">
      <Toolbar style={{flex: "1" }}>
        <MDBox component={NavLink} to="/" display="flex" alignItems="center" flexDirection="column">
          {brand && <MDBox component="img" src={brand} alt="Brand" width="2rem" marginLeft={"3rem"} bgColor={`${dark.main}`} />}
        </MDBox>
        <MDBox  sx={{ display:"flex", marginLeft: "2rem", flex: 2, justifyContent: "space-evenly", minWidth: "30rem"}}>
          {tabs.map((tab, index) => {

            return(      
            <IconButton  
              size="small"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true" 
              onClick={(event) => handleMenuClick(event, index)}
              color="inherit"
             
              >
             {tab.icon}
              <MDTypography variant="contained" component="h6" fontWeight="regular">{tab.label}</MDTypography>
            </IconButton>
           
        )})}
        </MDBox>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          // getContentAnchorEl={null}
          elevation={1}
        >
          {tabs[openTabMenuIndex ?? 0]?.subMenu?.map((subMenuItem, index) => {
            {console.log(subMenuItem)}
            return(
              <MenuItem
                key={index}
                onClick={() => handleMenuItemClick(subMenuItem.route)}
                component={Link}
                to={subMenuItem.route}
              >
                
                {subMenuItem.label}
              </MenuItem>
            );
})}
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default HorizontalAppBarWithTabsDropdown;
