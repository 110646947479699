import { Button, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import MDButton from 'components/MDButton';
import { Field, FieldProps, Form, Formik } from 'formik';
import React, { useState } from 'react';

interface Item {
  Id: string;
  Name: string;
  LibraryPath: string;
  ProductVersion: string;
  ProductLevel: {
    Code: number;
    Name: string;
  };
  Subscription: string; 
  CheckedOutDate: string; 
  CheckedOutBy: string; 
  Uri: string;
  Country: string;
  LauncherId: string;
  SerialNumber: string;
  UIAccessFlags: number;
}

const initialValues = {
    selectedOption: ''
  };

interface Props {
  items: Item[];
  onSubmit: (selectedItem: string) => void;
}

function ItemSelectionForm({ items, onSubmit }: any): JSX.Element {

  const [selectedItem, setSelectedItem] = useState<string | null>(null);

  return (
    <div>
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      <Form placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <Field name="selectedOption">
          {({ field }: FieldProps<string>)=> (
            <RadioGroup {...field}>
              {items.map((item: Item) => (
                <FormControlLabel
                  key={item.Id}
                  value={JSON.stringify({
                        uri: item.Uri,
                        name: item.Name
                    })} 
                  control={<Radio />}
                  label={item.Name}
                />
              ))}
            </RadioGroup>
          )}
        </Field>
        <MDButton 
          variant="gradient" 
          color="dark" 
          size="small"
          
          type='submit'
          >
          Submit
        </MDButton>

      </Form>
    </Formik>
    </div>
  );
};

export default ItemSelectionForm;
