import React, { SetStateAction, useEffect, useState } from 'react';

import {
    styled, 
} from '@mui/material/styles';

import { CardHeader, TextField as TextFieldOutlined, Checkbox as MuiCheckbox, Button, Link as MuiLink, FormControlLabel, Typography, CardMedia, Alert } from '@mui/material';
import { forgotPassword, confirmPassword as resetPassword} from 'auth/authService';
import brandDark from "assets/images/logos/peakrev.png";
import { useNavigate } from "react-router-dom";

import * as yup from 'yup';


const ResetPasswordContainer: any = styled("div")({
    backgroundColor: `rgba(250, 250, 250, 1)`,
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `row`,
    width: `100%`,
    height: `100%`,
    justifyContent: `center`,
    alignItems: `center`,
    padding: `0px`,
    boxSizing: `border-box`,
    // overflow: `hidden`,
});

const Main: any = styled("div")({
    display: `flex`,
    position: `absolute`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `center`,
    alignItems: `center`,
    padding: `0px`,
    boxSizing: `border-box`,
    alignSelf: `stretch`,
    flex: `1`,
    left: `0px`,
    top: `0px`,
    height: `1024px`,
    width: `1440px`,
});

const Card: any = styled("div")({
    backgroundColor: `rgba(255, 255, 255, 1)`,
    boxShadow: `0px 3px 14px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 5px 5px rgba(0, 0, 0, 0.2)`,
    borderRadius: `4px`,
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    width: `408px`,
    margin: `0px`,
    overflow: `hidden`,
    height: `600px`,
});


const CardHeaderStyled: any = styled(CardHeader)({ // Rename the variable to avoid conflicts
  alignSelf: `stretch`,
  margin: `0px`,
});

const CardContent: any = styled("div")({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `16px`,
    boxSizing: `border-box`,
    alignSelf: `stretch`,
    margin: `0px`,
    height: `400px`,
    width: `408px`,
});

const Form: any = styled("div")({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    alignSelf: `stretch`,
    margin: `0px`,
    height: `200px`,
    width: `376px`,
});

const StyledTextFieldOutlined: any = styled(TextFieldOutlined)({
    alignSelf: `stretch`,
    margin: `0px`,
});

const TextFieldOutlined1: any = styled(TextFieldOutlined)({
    alignSelf: `stretch`,
    margin: `16px 0px 0px 0px`,
});

const Checkbox: any = styled(FormControlLabel)({
    margin: `16px 0px 0px 0px`,
});

const StyledButtonContained: any = styled(Button)({
  
    alignSelf: `stretch`,
    margin: `16px 0px 0px 0px`,
    borderRadius: `4px`,
    background: `var(--Day-Primary-Main, #1976D2)`,
    color: "#fff",
    boxShadow: `0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)`,
});

const Actions: any = styled("div")({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    margin: `16px 0px 0px 0px`,
    height: `20px`,
    width: `220px`,
});

const Link: any = styled(MuiLink)({
    margin: `0px`,
    color: "#1976D2",
    height: "8px",
    background: "#FFF",

});

const Link1: any = styled(Link)({
    margin: `0px 0px 0px 8px`,
    color: "#1976D2"
});


const ForgotPassword = () => {
const [email, setEmail] = useState("");
const [code, setCode] = useState("");
const [password, setPassword] = useState("");
const [confirmPassword, setConfirmPassword] = useState("");
const [showCodeField, setShowCodeField] = useState(false);
const [deliveryMedium, setDeliveryMedium] = useState("Email");
const [success, setSuccess] = useState(false);
const [error, setError] = useState("");
const navigate = useNavigate();



useEffect(() => {
    if(error){
         <Alert severity="error"></Alert>
    }
}, [error]);
const validatePassword = async(password: string, confirmPassword: string) => {
    const schema = yup.object().shape({
        password: yup
            .string()
            .required('Password is required')
            .min(8, 'Password must be at least 8 characters')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
                'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
            ),
        confirmPassword: yup
            .string()
            .required('Confirm Password is required')
            .oneOf([yup.ref('password'), null], 'Passwords must match')
    });


    try {
        const errors = await schema.validate({ password, confirmPassword });
        console.log("🚀 ~ validatePassword ~ errors:", errors);
        if(errors) {
            return false;
        }
        return true;
    } catch (error) {
        
        return (error as Error).message;
    }
};

const handleCodeSubmit = async (e: React.SyntheticEvent<Element, Event>) => {
    e.preventDefault();
    console.log("event", e);
    console.log("code", code);
    try {
        // validate password
    const validationErrors = await validatePassword(password, confirmPassword);
     console.log("🚀 ~ handleCodeSubmit ~ isValidated:", validationErrors);
     if (validationErrors) {
        setError(validationErrors as string);
        throw error;
     }
     
     // handle confirm password
     const response = !error && await resetPassword(email, code, password);
     console.log("response", response);
     setSuccess(true);

     setTimeout(() => {
        setSuccess(false);
        setShowCodeField(false);
        setDeliveryMedium("");
        setPassword("");
        setConfirmPassword("");
        setCode("");
        setEmail("");
        navigate("/authentication/sign-in");
     }, 3000);

    } catch (error) {
        alert(`Fogot password failed: ${error.toString()}`);

    }
};


const handleSubmit = async (e: React.SyntheticEvent<Element, Event>) => {
  e.preventDefault();
  console.log("event", e);
  console.log("email:", email);
  try {
    const forgotResponse = await forgotPassword(email);
    console.log('forgot password', forgotResponse);
    if(forgotResponse && forgotResponse.CodeDeliveryDetails.DeliveryMedium === 'EMAIL') {
      setShowCodeField(true);
      setDeliveryMedium('Email');
    }
  } catch (error) {
    alert(`Forgot password failed: ${error.toString()}`);
  }
};
    return (
        <ResetPasswordContainer>
            <Main>
                <Card>
                    <CardMedia
                      component="img"
                      sx={{ height: 140 }}
                      image={brandDark}
                      title="peak rev"
                      style={{alignSelf: "center"}}
                    />
                    {!showCodeField && !success && <CardHeaderStyled title="Forgot Password?" subheader="Please enter your user name" />}
                    {showCodeField && !success && <CardHeaderStyled title="Forgot Password?" subheader={ `Please enter the code sent to your ${deliveryMedium}`} /> }

                    <CardContent>
                        {success &&<Typography variant="h6" color="textPrimary" gutterBottom>Password reset successfully</Typography>}
                        {error && <Alert severity="error"></Alert>}
                    <form onSubmit={handleSubmit}>

                        <Form>
                              {!showCodeField && !success && <StyledTextFieldOutlined id="email"  onChange={(event: { target: { value: SetStateAction<string> } }) =>
                                  setEmail(event.target.value)} placeholder="Email"
                              />}
                              {!success && showCodeField && <TextFieldOutlined1 id="code" type="text" inputProps={{ hidden: showCodeField}} onChange={(event: { target: { value: SetStateAction<string> } }) =>
                                  setCode(event.target.value)} placeholder="Code"
                              />}
                               {!success && showCodeField && (
                                <>
                                <TextFieldOutlined1 id="password" type="password" inputProps={{ hidden: showCodeField}} onChange={(event: { target: { value: SetStateAction<string> } }) =>
                                  setPassword(event.target.value)} placeholder="Password"
                                />
                                <TextFieldOutlined1 id="confirmPassword" type="password" inputProps={{ hidden: showCodeField}} onChange={(event: { target: { value: SetStateAction<string> } }) =>
                                    setConfirmPassword(event.target.value)} placeholder="Confirm Password"
                                  />
                                </>
                              )}
                              
                  
                        </Form>
                            </form>
                
                
                        {!showCodeField && !success && <StyledButtonContained variant="contained" onClick={handleSubmit}> Forgot Password</StyledButtonContained>}
                        { !success && showCodeField && <StyledButtonContained variant="contained" onClick={handleCodeSubmit}> Reset Password </StyledButtonContained>}

                        <Actions>
                          {success && <Link  href="/authentication/sign-in" variant="body2" underline="always">
                             Sign in here
                          </Link>}
                         
                        </Actions>
                    </CardContent>
                </Card>
            </Main>
        </ResetPasswordContainer>);
    };

export default ForgotPassword;
    