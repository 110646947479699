/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @ts-nocheck

import { useState, useEffect } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import https from 'https';

function MYOB(): JSX.Element {
  const [rememberMe, setRememberMe] = useState<boolean>(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const params = new URLSearchParams(window.location.search); 
  
  //Read the code value from the OAuth redirect
  const [urlCode, setCode] = useState(params.get('code'));
  const [authToken, setAuthToken] = useState("");
  // const [refreshToken, setRefreshToken] = useState("");
  // const client_id = '7158f2a1-7f65-4759-ac71-c159c05441d9';
  // const client_secret = 'J4kZg5H5nSI54aelR5IkOcMO';
  // // const callback_uri = 'http%3A%2F%2Flocalhost%3A3000%2Fpages%2Faccount%2Fsettings%2Fintegration%2Fmyob';
  // const callback_uri = 'http://localhost:3000/pages/account/settings/integration/myob';
  
  // const scope = 'CompanyFile';

  useEffect(() => {
    console.log('authorising');

    // const params = new URLSearchParams(window.location.search); 
    setCode (params.get('code')); 
    console.log('params:', params.get('code'));
    // setCode(params.get('code'));
    console.log('code:', urlCode);
    //request the Auth and Refresh tokens

      // const bodyContent = 'client_id=' + String(client_id) + 
      //   '&client_secret=' + String(client_secret) +
      //   '&grant_type=authorization_code&code='+code +   
      //   '&redirect_uri='+ callback_uri;
      
  //     const body = {
  //       client_id,
  //       client_secret,
  //       grant_type: 'authorization_code',
  //       code: urlCode,
  //       // 'scope': scope,
  //       redirect_uri: callback_uri,
  //     };

  //     const requestOptions = {
  //       method: 'POST',
  //       mode: 'cors',
  //       // credentials: 'same-origin',
  //       referrerPolicy: 'no-referrer',
  //       headers: { 
  //         'Content-Type': 'application/x-www-form-urlencoded',
  //         'Accept': '*/*',
  //         'Cache-Control': 'no-cache',
  //         'Accept-Encoding': 'gzip, deflate, br',
  //         'Connection': 'keep-alive',
  //         'Access-Control-Allow-Origin': '*',
  //         'Access-Control-Allow-Methods': 'POST',
  //         'Access-Control-Allow-Credentials': true,
  //         'Access-Control-Allow-Headers': '*',

  //          'x-myobapi-version': 'v2',
  //        },
  //       body: new URLSearchParams(body)
  //       // body: bodyContent
  //     };

  //     fetch('https://secure.myob.com/oauth2/v1/authorize/', requestOptions)
  //     .then((response) => {
  //       console.log('response:', response.status, response);
  //       if (!response.ok) {
  //         throw new Error('Network response was not OK');
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       console.log(data.access_token);
  //     })
  //     .catch((error) => {
  //       console.error('There has been a problem with your fetch operation:', error);
  //     });

  }, []);


  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            MYOB Integration Confirmation
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              {urlCode}
            </MDBox>
            <MDBox mb={2}>
              
            </MDBox>

 
            
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default MYOB;
