import { Avatar, Box, Card, Grid, Link, Typography } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout"
import logoMyob from 'assets/images/small-logos/myob-logo-vector.png';
import logoXero from 'assets/images/small-logos/xero.png';
import { Padding } from "@mui/icons-material";


/* Available Integrations */
const Integrations = (): JSX.Element => {
  return (
    <DashboardLayout>
      <Grid container spacing={3} justifyContent="center" >
        <Grid item xs={6} sx={{ padding: '10px' }} justifyContent="center" >
          <Typography variant="h3" component="h3" sx={{marginBottom:'16px'}}>
              Integrations
          </Typography>
          <Card sx={{ padding: '10px', marginBottom: '10px' }}>
            <Typography variant="h5" component="h2" color={'darkgray'}>
              Accounting Integrations
            </Typography>
            <Grid container spacing={3} py={2}>
              <Grid item xs={6}>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Link href="https://www.myob.com" target="_blank" rel="noopener noreferrer">
                    <img src={logoMyob} alt="MYOB logo" height={50} />
                  </Link>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Link href="https://www.myob.com" target="_blank" rel="noopener noreferrer">
                    <img src={logoXero} alt="MYOB logo" height={70} />
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </Card>
          <Card sx={{ padding: '10px' }}>
            <Typography variant="h5" component="h2" color={'darkgray'}>
              Property Management System Integrations
            </Typography>
            <Grid container spacing={3} py={2}>
              <Grid item xs={6}>
                <Box display="flex" alignItems="center">
                  <Avatar src={logoMyob} alt="MYOB logo" variant="rounded" />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box display="flex" alignItems="center">
                  <Avatar src={logoMyob} alt="MYOB logo" variant="rounded" />
                </Box>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  )
}

export default Integrations;