/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// NewUser page components
import FormField from "layouts/dashboards/properties/new-property/components/FormField";

function Property({ formData }: any): JSX.Element {
  const { formField, values, errors, touched } = formData;
  const { propertyName, propertyDescription, abn, rooms} = formField;
  const { propertyName: propertyNameV, propertyDescription: propertyDescriptionV} = values;

  return (
    <MDBox>
      <MDTypography variant="h5" fontWeight="bold">
        Property
      </MDTypography>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormField
              type={propertyName.type}
              label={propertyName.label}
              name={propertyName.name}
              value={propertyNameV}
              placeholder={propertyName.placeholder}
              error={errors.propertyName && touched.propertyName}
            />
          </Grid>
          <Grid item xs={12}>
            <MDBox mt={-1.625}>
              <FormField
                type={propertyDescription.type}
                label={propertyDescription.label}
                name={propertyDescription.name}
                value={propertyDescriptionV}
                placeholder={propertyDescription.placeholder}
              />
            </MDBox>
          </Grid>
          <Grid item xs={6}>
            <MDBox mt={-1.625}>
              <FormField
                type={abn.type}
                label={abn.label}
                name={abn.name}
                placeholder={abn.placeholder}
                error={errors.abn && touched.abn}
              />
            </MDBox>
          </Grid>
          <Grid item xs={6}>
            <MDBox mt={-1.625}>
              <FormField
               type={rooms.type}
               label={rooms.label}
               name={rooms.name}
               placeholder={rooms.placeholder}
               error={errors.rooms && touched.rooms}
              />
            </MDBox>
          </Grid>
        </Grid>
        
      </MDBox>
    </MDBox>
  );
}

export default Property;
