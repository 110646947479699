/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import * as React from "react";
import App from "App";


// Material Dashboard 2 PRO React TS Context Provider
import { MaterialUIControllerProvider } from "context";

import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

// ReactDOM.render(
//   //<React.StrictMode>
//     <BrowserRouter>
//       <MaterialUIControllerProvider>
//         <App />
//       </MaterialUIControllerProvider>
//     </BrowserRouter>
//  //</React.StrictMode>
//  ,
//  document.getElementById("root")
// );

root.render(
    <BrowserRouter>
      <MaterialUIControllerProvider>
        <App />
      </MaterialUIControllerProvider>
    </BrowserRouter>

);
