/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import colors from "assets/theme/base/colors";
import rgba from "assets/theme/functions/rgba";

const { dark } = colors;

// types
type Types = any;

const appBar: Types = {
  defaultProps: {
    color: "white",
  },

  styleOverrides: {
    root: {
      boxShadow: "none",
      borderBottom: `3px solid ${rgba(
        dark.main, 3)}`
    },
  },
};

export default appBar;
