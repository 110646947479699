import React, { createContext, useContext } from 'react';

// Define the type for your cache object
type CacheType = { [key: string]: any };

// Create the cache context
const CacheContext = createContext<CacheType | undefined>(undefined);

// Create a hook to access the cache context
export function useCache() {
  const context = useContext(CacheContext);
  if (!context) {
    throw new Error('useCache must be used within a CacheProvider');
  }
  return context;
}

// Create a CacheProvider component to wrap your app
export function CacheProvider({ children }: { children: React.ReactNode }) {
  const cache: CacheType = {}; // Initialize your cache object
  return <CacheContext.Provider value={cache}>{children}</CacheContext.Provider>;
}
