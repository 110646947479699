/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "new-property-form",
  formField: {
    propertyDescription: {
      name: "propertyDescription",
      label: "Description",
      type: "text",
      errorMsg: "Property Description is required.",
    },
    propertyName: {
      name: "propertyName",
      label: "Property Name",
      type: "text",
      errorMsg: "Property Name is required.",
    },
    abn: {
      name: "abn",
      label: "ABN",
      type: "text",
      errorMsg: "ABN is required.",
    },
    rooms: {
      name: "rooms",
      label: "Number of Rooms",
      type: "text",
      errorMsg: "Rooms is required.",
    },
  },
};

export default form;
