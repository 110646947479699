import React, { SetStateAction, useState } from 'react';

import {
    styled, 
} from '@mui/material/styles';

import { CardHeader, TextField as TextFieldOutlined, Checkbox as MuiCheckbox, Button, Link as MuiLink, FormControlLabel, Typography, CardMedia } from '@mui/material';
import { signIn } from 'auth/authService';
import brandDark from "assets/images/logos/peakrev.png";


const LargeLightLogIn1: any = styled("div")({
    backgroundColor: `rgba(250, 250, 250, 1)`,
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `row`,
    width: `100%`,
    height: `100%`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    // overflow: `hidden`,
});

const Main: any = styled("div")({
    display: `flex`,
    position: `absolute`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `center`,
    alignItems: `center`,
    padding: `0px`,
    boxSizing: `border-box`,
    alignSelf: `stretch`,
    flex: `1`,
    left: `0px`,
    top: `0px`,
    height: `1024px`,
    width: `1440px`,
});

const Card: any = styled("div")({
    backgroundColor: `rgba(255, 255, 255, 1)`,
    boxShadow: `0px 3px 14px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 5px 5px rgba(0, 0, 0, 0.2)`,
    borderRadius: `4px`,
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    width: `408px`,
    margin: `0px`,
    overflow: `hidden`,
    height: `500px`,
});


const CardHeaderStyled: any = styled(CardHeader)({ // Rename the variable to avoid conflicts
  alignSelf: `stretch`,
  margin: `0px`,
});

const CardContent: any = styled("div")({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `16px`,
    boxSizing: `border-box`,
    alignSelf: `stretch`,
    margin: `0px`,
    height: `312px`,
    width: `408px`,
});

const Form: any = styled("div")({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    alignSelf: `stretch`,
    margin: `0px`,
    height: `128px`,
    width: `376px`,
});

const StyledTextFieldOutlined: any = styled(TextFieldOutlined)({
    alignSelf: `stretch`,
    margin: `0px`,
});

const TextFieldOutlined1: any = styled(TextFieldOutlined)({
    alignSelf: `stretch`,
    margin: `16px 0px 0px 0px`,
});

const Checkbox: any = styled(FormControlLabel)({
    margin: `16px 0px 0px 0px`,
});

const StyledButtonContained: any = styled(Button)({
  
    alignSelf: `stretch`,
    margin: `16px 0px 0px 0px`,
    borderRadius: `4px`,
    background: `var(--Day-Primary-Main, #1976D2)`,
    color: "#fff",
    boxShadow: `0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)`,
});

const Actions: any = styled("div")({
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    margin: `16px 0px 0px 0px`,
    height: `20px`,
    width: `220px`,
});

const Link: any = styled(MuiLink)({
    margin: `0px`,
    color: "#1976D2",
    height: "8px",
    background: "#FFF",

});

const Link1: any = styled(Link)({
    margin: `0px 0px 0px 8px`,
    color: "#1976D2"
});


function LargeLightLogIn(): JSX.Element {
const [email, setEmail] = useState("");
const [password, setPassword] = useState("");
const [errorMessage, setErrorMessage] = useState("");

const handleSubmit = async (e: React.SyntheticEvent<Element, Event>) => {
  e.preventDefault();
  console.log("event", e);
  console.log("email", email);
  console.log("password", password);
  try {
    const session = await signIn(email, password);
    console.log('Sign in successful', session);
    if (session && typeof session.AccessToken !== 'undefined') {
      // sessionStorage.setItem('accessToken', session.AccessToken);
      if (sessionStorage.getItem('accessToken')) {
        const allDashboards = JSON.parse(sessionStorage.getItem("dashboards"));
        console.log("🚀 ~ handleSubmit ~ allDashboards:", allDashboards);
    
        window.location.href = `/analytics/${allDashboards[0].id}`;
      } else {
        console.error('Session token was not set properly.');
      }
    } else {
      console.error('SignIn session or AccessToken is undefined.');
    }
  } catch (error) {
    alert(`Sign in failed: ${error.toString()}`);
  }
};
    return (
        <LargeLightLogIn1>
            <Main>
                <Card>
                    <CardMedia
                      component="img"
                      sx={{ height: 140 }}
                      image={brandDark}
                      title="peak rev"
                      style={{alignSelf: "center"}}
                    />
                    <CardHeaderStyled title="Welcome back!" subheader="Please login to your account" />
                    <CardContent>
                    <form onSubmit={handleSubmit}>

                        <Form>
                              <StyledTextFieldOutlined id="email"  onChange={(event: { target: { value: SetStateAction<string> } }) =>
                                  setEmail(event.target.value)} placeholder="Email"
                              />
                              
                              <TextFieldOutlined1 id="password" type="password" onChange={(event: { target: { value: SetStateAction<string> } }) =>
                                  setPassword(event.target.value)} placeholder="Password"
                              />
                        </Form>
                            </form>
                
                        <StyledButtonContained variant="contained" onClick={handleSubmit}> Log in </StyledButtonContained>
                        <Actions>
                          <Link  href="/authentication/forgot-password" variant="body2" underline="always">
                             Forgot Password
                          </Link>
                          <Link1/>
                        </Actions>
                    </CardContent>
                </Card>
            </Main>
        </LargeLightLogIn1>);
    }

export default LargeLightLogIn;
    