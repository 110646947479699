

import React, { Component, ReactNode, useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { refreshAccessToken, validateAccessToken } from "auth/authService";

const PrivateRoute = ({ children }: { children: ReactNode }) => {
  const [auth, setLoggedIn] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    //console.log("Checking loggedin status");
    const checkAuthStatus = async () => {
      try {
        
          const accessToken = sessionStorage.getItem('accessToken');
          const refreshToken = sessionStorage.getItem("refreshToken");
          const isValidToken = accessToken && validateAccessToken(accessToken);
        
        if (accessToken && isValidToken) {
          setLoggedIn(true);
        } else if(accessToken && refreshToken) {
          const refreshedAccessToken = await refreshAccessToken();
          const isValidAccessToken = refreshedAccessToken && validateAccessToken(refreshedAccessToken);
          if (isValidAccessToken) {
            setLoggedIn(true);
          } else {
            setLoggedIn(false);
          }
        } else {
          setLoggedIn(false);
        }
      } catch (e) {
        console.log("Cognito Auth Error", e);
        setLoggedIn(false);
      }
      setLoading(false);
    };

    checkAuthStatus();
  }, [auth]); // Provide an empty dependency array here

  // If authorized, return an outlet that will render child elements
  // If not, return an element that will navigate to the login page
  if (!loading) {
    //console.log("returning")
    return auth ? <> {children} <Outlet /> </> : <Navigate to="/authentication/sign-in" />;
  }
};

export default PrivateRoute;
